<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The rate constant for the first-order reaction shown below is
        <stemble-latex content="$\text{k}=$" />
        <number-value :value="rateConstant" unit="\text{s}^{-1}" />
        at
        <stemble-latex content="$165^\circ\text{C.}$" />
      </p>

      <p class="mb-3 pl-8">
        <chemical-latex content="A -> products" />
      </p>

      <p class="mb-2">
        How long would it take for
        <stemble-latex content="$\text{[A]}$" />
        to decrease to
        <number-value :value="percentage" unit="\%" />
        of its original value?
      </p>

      <calculation-input
        v-model="inputs.time"
        prepend-text="$\text{t:}$"
        append-text="$\text{s}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question94',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        time: null,
      },
    };
  },
  computed: {
    rateConstant() {
      return this.taskState.getValueBySymbol('rateConstant').content;
    },
    percentage() {
      return this.taskState.getValueBySymbol('percentage').content;
    },
  },
};
</script>
